<template>
  <!--begin::Content-->
  <div id="kt_account_profile_details" class="collapse show">
    <!--begin::Card body-->
    <div class="card-body border-top p-0">
      <!--begin::Input group-->
      <!-- <div class="row mb-6"> -->
      <div style="height: 64vh; width: 100%">
        <l-map
          v-model="zoom"
          v-model:zoom="zoom"
          :zoomAnimation="true"
          :options="{ zoomControl: false }"
          :center="[-6.304159, 106.724997]"
        >
          <l-tile-layer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            layer-type="base"
            name="OpenStreetMap"
          />
          <l-control-layers />
          <l-control-zoom position="bottomright"></l-control-zoom>
          <template v-for="(item, index) in markers" :key="index">
            <l-marker :lat-lng="[item.person.latitude, item.person.longitude]">
              <l-icon :icon-url="icon" :icon-size="iconSize" />
              <l-popup>
                <div class="card">
                  <div class="card-body">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <img
                        class="img-fluid"
                        style="max_height: 100px"
                        :src="item.person.foto"
                        alt="foto kegiatan"
                      />
                      <div class="flex-grow-1">
                        <div class="d-flex flex-column">
                          <div class="col-12">{{ item.person.name }}</div>
                          <div class="col-12">
                            {{ item.person.phone_number }}
                          </div>
                          <div class="col-12">{{ item.person.alamat }}</div>
                          <div class="col-12">{{ item.person.position }}</div>
                          <div class="col-12">{{ item.person.profession }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </l-popup>
            </l-marker>
          </template>
        </l-map>
      </div>
      <!-- </div> -->
      <!--end::Input group-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::Content-->
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useStore } from "vuex";
// import { useRouter } from "vue-router";
// import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LControlZoom,
} from "@vue-leaflet/vue-leaflet";
import "leaflet/dist/leaflet.css";

export default defineComponent({
  name: "FigureMapsKontra",
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LControlZoom,
  },
  setup() {
    setCurrentPageTitle("Peta Tokoh");

    const store = useStore();
    // const router = useRouter();
    store.dispatch("AllPersonDashboard", "kontra");

    store.commit("SET_ACTIVE_MENU", "person-map");

    const center = ref([-6.304159, 106.724997]);

    const zoom = ref(12);

    const iconSize = ref([50, 50]);

    const path = window.location.origin + "/";
    const icon = path + "other/png/Group 278.png";

    const markers = computed(() => store.state.PersonDashboardModule.all);

    watch(markers, () => {
      // console.log(markers.value);
    });

    return {
      center,
      markers,
      zoom,
      icon,
      iconSize,
    };
  },
});
</script>
